
import { computed, defineComponent, PropType } from "vue";
import { formatCurrencyAndQuantity } from '../../../../../core/helpers/utils';
import moment from "moment";

import { ContractSupplyItemResponse } from "@/core/interfaces/ApiResponses";

interface ContractSupplyItem extends ContractSupplyItemResponse {
  name?: string;
}

export default defineComponent({
  name: "contract-supplies",
  props: {
    items: {
      type: Array as PropType<Array<ContractSupplyItem>>,
    },
  },
  setup() {
    return {
      formatDate: computed(() => moment),
      formatCurrencyAndQuantity,
    };
  },
});
